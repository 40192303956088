<template>
  <hotels-module-component></hotels-module-component>
</template>

<script>
import HotelsModuleComponent from "@/components/admin/modules/HotelsModuleComponent";

export default {
  name: "HotelsModule",
  title: "Gestión de Hoteles | Baja California Health Tourism",
  components: { HotelsModuleComponent },
};
</script>

<style scoped></style>
